import React from "react";

const RemainingHeightContext = React.createContext<{
  offsetHeight: number | undefined;
  wrapperHeight: number | undefined;
  removed?: boolean;
  focused?: boolean;
}>({ offsetHeight: undefined, wrapperHeight: undefined });

export const RemainingHeightContextProvider = RemainingHeightContext.Provider;

export default RemainingHeightContext;

import { ServerImage } from "types/module";

import { callAPIHandler } from "./_base";

// Files
interface GetModuleFileListParams {
  id: string;
  module: string;
  limit: number;
  preview?: boolean;
}

type getModuleFileListQueryKey = [string, GetModuleFileListParams];

interface getModuleFileListResponse {
  count: number;
  files: Record<number, ServerImage>;
}

export const getModuleFileList = ({
  queryKey,
  pageParam = 1,
}: {
  queryKey: getModuleFileListQueryKey;
  pageParam?: number;
}) => {
  const [, { limit, module, preview = false, id }] = queryKey;
  return callAPIHandler<getModuleFileListResponse>(
    "get",
    `/api/admin/${module}/upload-list/${id}`,
    {
      page: pageParam,
      limit,
      preview: preview ? "1" : "0",
    },
    true
  );
};
getModuleFileList.queryKey = "get,/api/admin/{{module}}/upload-list/{{id}}";

interface UploadModuleFileParams {
  id: string;
  module: string;
  files: File[];
}

export const uploadModuleFile = ({
  files,
  id,
  module,
}: UploadModuleFileParams) =>
  callAPIHandler(
    "post",
    `/api/admin/${module}/upload/${id}`,
    {
      files,
    },
    true,
    { withFiles: true }
  );

export const deleteModuleFile = ({
  module,
  id,
  uids,
}: {
  uids: string[];
  id: string;
  module: string;
}) =>
  callAPIHandler(
    "delete",
    `/api/admin/${module}/delete-file/${id}`,
    {
      uids,
    },
    true
  );

interface getModuleFileInfoParam {
  module: string;
  id: string;
}

type getModuleFileInfoQueryKey = [string, getModuleFileInfoParam];

export const getModuleFileInfo = ({
  queryKey,
}: {
  queryKey: getModuleFileInfoQueryKey;
}) => {
  const [, { module, id }] = queryKey;
  return callAPIHandler<{ files: ServerImage[] }>(
    "get",
    `/api/admin/${module}/upload-list/${id}`,
    { page: 1, limit: 999 },
    true
  );
};

// Chunk upload
interface UploadModuleFileChunkParams {
  data: Blob;
  part: number;
  total: number;
  uuid: string;
  filename: string;
  module: string;
  id: string;
}

export const uploadModuleFileChunk = ({
  data,
  part,
  total,
  uuid,
  module,
  id,
  filename,
}: UploadModuleFileChunkParams) =>
  callAPIHandler(
    "post",
    `/api/admin/${module}/chunk-upload`,
    {
      data,
      part,
      total,
      uuid,
      id,
      module,
      filename,
    },
    true,
    { withFiles: true }
  );

interface GetModuleFileChunkStatusParams {
  uuid: string;
  module: string;
  id: string;
}

export interface GetModuleFileChunkStatusResponse {
  finished: 0 | 1;
}

export const getModuleFileChunkStatus = ({
  uuid,
  module,
  id,
}: GetModuleFileChunkStatusParams): Promise<GetModuleFileChunkStatusResponse> =>
  callAPIHandler(
    "get",
    `/api/admin/${module}/chunk-upload-record`,
    {
      uuid,
      id,
    },
    true
  );

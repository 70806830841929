import { mapValues } from "lodash";
import qs from "query-string";

const timing = {
  useProductionQuestionDetail: 250,
  ScaffoldReady: 750,
  ScaffoldLoadReady: 250,
  ScaffoldPreview: 250,
  ItemOffsetCalcHeight: 250,
  ItemOffsetConfirmDisabled: 75,
  ItemOffsetFocused: 150,
  TableForceUpdate: 450,
  TableHeight: 400,
  FetchingReady: 750,
  FetchingDelay: 250,
};

const search = qs.parse(window.location.search);
const waitFactor = search.w
  ? Number(search.w)
  : Number(process.env.REACT_APP_WAIT_FACTOR);
const finalTiming = mapValues(timing, (s) => Math.round(s * waitFactor));

export default finalTiming;

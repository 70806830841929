import timing from "config/timing";
import React, { useEffect, useState } from "react";
import { useIsFetching } from "react-query";

type Props = {
  setReady: React.Dispatch<React.SetStateAction<boolean>>;
};

const LoadingReadyIndicator = ({ setReady: setContextReady }: Props) => {
  const [ready, setReady] = useState(false);

  const isFetching = useIsFetching();

  useEffect(() => {
    if (isFetching) {
      return;
    }
    const timer = setTimeout(() => {
      setContextReady(true);
      setTimeout(() => {
        setReady(true);
      }, timing.FetchingDelay);
      // If no network request in 500ms, set as ready
    }, timing.FetchingReady);
    return () => {
      clearTimeout(timer);
    };
  }, [isFetching, setContextReady]);

  if (!ready || isFetching) {
    return <></>;
  }

  return <div id="ready" />;
};

export default LoadingReadyIndicator;
